body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #423f40;
  background-color: #fafafa;
}

.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 35px; /* change this value to required width */
  text-align: center;
  filter: invert(60%);
  opacity: 0.5;
}
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 35px; /* change this value to required width */
  text-align: center;
  filter: invert(60%);
  opacity: 0.5;
}

.defaultfontsize {
  font-size: 20px;
}

.averagefontsize {
  font-size: 18px;
}
.normalsmallfontsize {
  font-size: 16px;
}
.standardfontsize {
  font-size: 14px;
}
.defaultstandardfontsize {
  font-size: 12px;
}
.defaultsmallfontsize {
  font-size: 10px;
}
#redcircledot {
  background: #f00;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 5px;
  opacity: 80%;
}
.lineentry {
  text-overflow: ellipsis;
  line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.twolineentry {
  text-overflow: ellipsis;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.darkgreencolor {
  color: #458e3b;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.announcementpanel,
.prayerpanel {
  border: 1px solid #dddddd;
  background-color: #fff;
  color: #444444;
}
.announcementpanelheader,
.prayerpanelheader {
  color: #000000;
}

.announcementpaneldetails,
.prayerpaneldetails {
  color: #444444;
}

.announcementpanelblue,
.prayerpanelblue {
  border: 1px solid #eaf2fd;
  background-color: #eaf2fd;
  border: 1px solid #dddddd;
  color: #444444;
}

.announcementpanelheaderblue,
.prayerpanelheaderblue {
  color: #277af5;
}

.lightframecolor {
  border: 1px solid #cdcdcd;
}

.lightgreygreenframecolor {
  border: 1px solid #d3e5d0;
}

.redframecolor {
  border: 1px solid #d93d3f;
  background: #f8d7da;
}

.greenframecolor {
  border: 1px solid #51a847;
}

.greenscanindicator {
  border-top: 3px solid #fff;
}

.whitescanindicator {
  border-top: 3px solid #fff;
}

.mycatholicsgred {
  color: #d93d3f;
}

.mycatholicsggreen {
  color: #3d811e;
}

.showvideowrapper {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  height: 260px;
}

.hidevideowrapper {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  height: 30px;
}

.showvideoarea {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  /* height: 260px; */
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.hidevideoarea {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: none;
  height: 0px;
}

.dotlegend {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.image-upload > input {
  visibility: hidden;
  width: 0;
  height: 0;
  display: none;
}

.image-upload {
  margin-top: -48px;
  margin-left: 140px;
  width: 50px;
}

.image-upload-icon {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 4px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

button:focus {
  outline: 0;
}
